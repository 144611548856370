@keyframes piscar {
    0% {
      box-shadow: 0 0 5px rgba(239, 68, 68, 0.5);
    }
    50% {
      box-shadow: 0 0 10px rgba(239, 68, 68, 1),
                  0 0 20px rgba(239, 68, 68, 0.5);
    }
    100% {
      box-shadow: 0 0 5px rgba(239, 68, 68, 0.5);
    }
  }
  
  .piscar {
    animation: piscar 1s infinite;
  }